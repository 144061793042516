import React from 'react'
import Button from './common/Button';

function FormComponent() {
  const handleButtonClick = () => {
    console.log("Button was clicked!");
  };

  return (
    <div id="tab1" className="card tab-content">
    <div className="card-content">
      <div className="">
        <p className="panel-heading custom-bg">
          <img src="images/facebook-icon.png" alt="Facebook Icon" className="img-fixer2"/>
          Facebook Ad Copy
        </p>
        <form className="form-data">
          <div className="field">
            <label className="label">1. Product/Brand Name</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Example: Honey Mask"
              />
            </div>
          </div>

          <div className="field">
            <label className="label">2. Product Description (optional)</label>
            <div className="control">
              <textarea
                className="textarea"
                placeholder="Example: Organic Honey Glo Rejuvenating Face Serum"
              ></textarea>
            </div>
          </div>

          <div className="field">
            <label className="label">3. Occasion</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Example: Black Friday"
              />
            </div>
          </div>

          <div className="field">
            <label className="label">4. Promotion</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Example: 15% off, Free shipping"
              />
            </div>
          </div>

          <div className="field">
            <label className="label">5. Choose a tone (optional)</label>
            <div className="control">
              <div className="select">
                <select>
                  <option>Example: 😀 Friendly</option>
                  <option>😎 Casual</option>
                  <option>👨‍💼 Professional</option>
                  <option>💎 Luxury</option>
                </select>
              </div>
            </div>
          </div>
        </form>
        <div className="field is-grouped">
          <div className="control">
          <Button text="Generate Ad Copy" onClick={handleButtonClick} />
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default FormComponent
