import React from 'react'

function Footer() {
  return (
    <section className="foot-b">
    <div className="columns footer container-block">
      <div className="column">
        <div className="footer-logo">
          <a className="#" href="https://scalehacks.com">
            <img src="images/Scale_Hacks.png" alt="ScaleHacks Logo"/>
          </a>
        </div>
        <p className="width-2">
          Copyright © 2023 ScaleHacks.com. All Rights Reserved
        </p>
      </div>
    </div>
  </section>
  )
}

export default Footer