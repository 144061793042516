import React from 'react'

function OutputSection() {
  return (
    <div className="card ">
        <div className="card-content">
          <div className="columns">
            <div className="column">
              <div className="tabs is-medium navi2">
                <ul>
                  <li className="is-active">
                    <a href="https://scalehacks.com/">All Output</a>
                  </li>
                </ul>
              </div>
     
              <div className="">
                <p className="form-control">
                  <textarea id="shareCaption" className="textarea">
                    5555Lorem ipsum leo risus, porta ac consectetur ac,
                    vestibulum at eros. Donec id elit non mi porta gravida
                    at eget metus. Cum sociis natoque penatibus et magnis
                    dis parturient montes, nascetur ridiculus mus. Cras
                    mattis consectetur purus sit amet fermentum.
                  </textarea>
                </p>
                <button className="btn copy__btn" onClick="copyCaption()">
                  <i className="far fa-copy"></i> Copy Caption
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default OutputSection


