import React from 'react'

function Header() {
  return (
    <article class="panel is-info spacer-1">
    <div class="head">
      <div class="nav">
        <nav class="navbar" role="navigation" aria-label="main navigation">
          <div class="navbar-brand">
            <a class="navbar-item" href="https://scalehacks.com/">
              <img src="images/sh.png" alt="ScaleHacks Logo"/>
            </a>
          </div>

          <div id="navbarBasicExample" class="navbar-menu">
            <div class="navbar-end">
              <div class="navbar-item has-dropdown is-hoverable">
                <a
                  class="navbar-link"
                  href="https://scalehacks.com/ad-suggest/"
                >
                  Products
                </a>

                <div class="navbar-dropdown">
                  <a
                    class="navbar-item"
                    href="https://scalehacks.com/ad-suggest"
                  >
                    Ad Suggest
                  </a>
                </div>
              </div>
              <a
                class="navbar-item"
                href="https://scalehacks.com/consultation"
              >
                Consultation
              </a>
              <a class="navbar-item" href="https://scalehacks.com/blogs">
                Blog
              </a>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </article>
  )
}

export default Header