import React from 'react'

function LeftSidebar() {
  return (
    <article className="panel is-primary navi">
        <p className="panel-heading bg-pannel">Ads</p>
        <a className="panel-block active" href="https://scalehacks.com/">
          <img src="images/facebook-icon.png" alt="Facebook Icon" className="img-fixer"/>
          Facebook Ad Copy
        </a>
        <a className="panel-block" href="https://scalehacks.com/">
          <img src="images/facebook-icon.png" alt="Facebook Icon" className="img-fixer"/>
          Facebook Ad Headline
        </a>
        <a className="panel-block" href="https://scalehacks.com/">
          <img src="images/google-icon.png" alt="Google Icon" className="img-fixer" />
          Google Ad Headline
        </a>
        <a className="panel-block" href="https://scalehacks.com/">
          <img src="images/google-icon.png" alt="Google Icon" className="img-fixer" />
          Google Ad Descriptions
        </a>
      </article>
  )
}

export default LeftSidebar