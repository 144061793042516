import "bulma/css/bulma.min.css";
import "./App.css";

// Import the components
import Header from "./components/common/Header";
import LeftSidebar from "./components/LeftSidebar";
import FormComponent from "./components/FormComponent";
import OutputSection from "./components/OutputSection";
import Footer from "./components/common/Footer";


function App() {
  return (
    <>
      <Header />
      <div class="columns spacing-main">
        <div class="column is-one-quarter">
          <LeftSidebar />
        </div>
        <div class="column">
          <FormComponent />
        </div>
        <div class="column">
          <OutputSection />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default App;
